import Vue from "vue";
import Login from "./components/Login.vue";
import GSignInButton from 'vue-google-signin-button';
import * as Msal from 'msal';
Vue.use(GSignInButton)
Vue.use(Msal)
let vm = new Vue({
  el: "#login",
  components: {
    Login
  },
  template: '<Login ref="LoginRef" />'

});
window.Login = vm;
