import axios from 'axios';
var ApiService = {

  Login: function (data) {
    var headers = {
      'X-Device-Token': data.deviceToken,
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'POST',
      url: deltax.loginAuthenticationUrl,
      data: { "username": data.username, "password": data.password, "portal": data.portalDomain, "LoginProviderType": 1 },
      headers: headers
    })
  },
  createSessionAction: function (response) {
    var deviceToken = response.data.deviceToken != null ? response.data.deviceToken : '';
    return axios({
      method: 'POST',
      url: deltax.createSessionUrl,
      data: { "userId": response.data.userId, "accessToken": response.data.accessToken, "deviceToken": deviceToken },
      contentType: 'application/json'
    })
  },
  validateOTP: function (tempAccessToken, OTPcode) {
    const accessToken = 'Bearer '.concat(tempAccessToken);
    var authorizationHeaders = {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'POST',
      url: deltax.twoFactorLoginAuthenticationUrl,
      data: { "otp": OTPcode },
      headers: authorizationHeaders
    })
  },
  getRedirectUrl () {
    var headers = {
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'GET',
      url: deltax.redirectUrl,
      headers: headers
    })
  },
  createSessionAndRedirect: function (returnUrl, response) {
    show_loading_bar(70);
    // Create Cookie and  Session for Generated Access Token
    ApiService.createSessionAction(response)
      .then((res) => {
        show_loading_bar({
          delay: 0.5,
          pct: 100,
          finish: function () {
            // Redirect after successful login page (when progress bar reaches 100%)
            if (returnUrl == '' || returnUrl == '/') {
              ApiService.getRedirectUrl()
                .then((redirectData) => {
                  window.location.href = redirectData.data.redirectUrl;
                })
            } else {
              window.location.href = returnUrl + window.location.hash;
            }
          }
        });
      })
      .catch((error) => {
        this.OTPSpinnerStyle = 'display: none;';
        console.log('something went wrong:' + error.response)
      })
  },
  ResendOtp: function (tempAccessToken) {
    const accessToken = 'Bearer '.concat(tempAccessToken);
    var authorizationHeaders = {
      'Authorization': accessToken,
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'GET',
      url: deltax.ResendOTPUrl,
      data: '',
      headers: authorizationHeaders
    })
  },
  convertErrorRespsonse: function (errorResponse) {
    var customizedErrorMessage = '';
    if (errorResponse.errorCode == 51) {
      if (errorResponse.data != null) {
        var currentLoginAttempts = errorResponse.data.currentLoginAttempts;
        var maxLoginAttempts = errorResponse.data.maxLoginAttempts;
        var attemptsLeft = maxLoginAttempts - currentLoginAttempts;
        if (attemptsLeft == 0) {
          customizedErrorMessage = "Wrong credentials entered for the third time.<br>" +
            "Your account is locked for 15 minutes.<br>" +
            "You can use <a href='Account/ForgotPassword'>forgot password</a> to reset password and proceed.";
        } else {
          customizedErrorMessage = "You have <b>" + attemptsLeft + "</b> attempts left." +
            "<br>Invalid attempts shall result in your account getting locked.<br>" +
            "You can use <a href='Account/ForgotPassword'>forgot password</a> to reset password and proceed.";
        }
      } else {
        customizedErrorMessage = "Invalid attempts shall result in your account getting locked.<br>" +
          "You can use <a href='Account/ForgotPassword'>forgot password</a> to reset password and proceed.";
      }
    } else if (errorResponse.errorCode == 52) {
      customizedErrorMessage = "User access has expired. Please contact support.";
    } else if (errorResponse.errorCode == 53) {
      customizedErrorMessage = errorResponse.message;
    } else if (errorResponse.errorCode == 54) {
      customizedErrorMessage = "Your account has been disabled by the admin. Please get in touch with us at <b>ace@deltax.com </b>";
    } else if (errorResponse.errorCode == 55) {
      customizedErrorMessage = errorResponse.message;
    } else if (errorResponse.errorCode == 56) {
      customizedErrorMessage = "You are not authorized to access this application";
    } else if (errorResponse.errorCode == 57) {
      customizedErrorMessage = "Invalid OTP";
    } else if (errorResponse.errorCode == 59) {
      customizedErrorMessage = errorResponse.message;
    } else if (errorResponse.errorCode == 64) {
      customizedErrorMessage = errorResponse.message;
    } else if (errorResponse.errorCode == 66) {
      customizedErrorMessage = "Access denied by policy. Please contact your account manager for help.";
    } else if (errorResponse.errorCode == 74) {
      customizedErrorMessage = errorResponse.message
      var redirectResetPassword = `/App/Account/Users#/ResetPassword?hash=${errorResponse.data.hash}&isExpiredPassword=true`;
      window.location.href = redirectResetPassword;
    }
    return customizedErrorMessage;
  },
  LoginWithGoogle: function (data) {
    var headers = {
      'X-Device-Token': data.deviceToken,
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'POST',
      url: deltax.loginAuthenticationUrl,
      data: { "username": data.googleUsername, "password": '', "portal": data.portalDomain, "LoginProviderType": 2 },
      headers: headers
    })
  },
  LoginWithMicrosoft: function (data) {
    var headers = {
      'X-Device-Token': data.deviceToken,
      'Content-Type': 'application/json'
    }
    return axios({
      method: 'POST',
      url: deltax.loginAuthenticationUrl,
      data: { "username": data.username, "password": data.password, "portal": data.portalDomain, "LoginProviderType": 3 },
      headers: headers
    })
  },
  getOTPConfirmationNote: function (response) {
    var confirmationMessage = "An OTP has been sent to your registered";
    if (response.isEmailSent) {
      confirmationMessage = confirmationMessage + " email.";
    }
    if (response.isSmsSent) {
      confirmationMessage = confirmationMessage + " and mobile";
    }
    return confirmationMessage;
  },
  getResendOTPConfirmationNote: function (response) {
    var confirmationMessage = "A new OTP has been generated and sent to your registered";
    if (response.isEmailSent) {
      confirmationMessage = confirmationMessage + " email.";
    }
    if (response.isSmsSent) {
      confirmationMessage = confirmationMessage + " and mobile";
    }
    return confirmationMessage;
  },
  verifyTwoFactorEnabled: function (data, response) {
    if (response.status == 200) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const clientId = urlParams.get('client_id');
      if (window.location.host === 'dxauth.adbox.pro' && clientId === 'dxdatastudioconnect') {
        this.redirectToConnectorScript(response, urlParams);
      } else {
        ApiService.createSessionAndRedirect(data.returnUrl, response);
      }
    } else { // 2FA Enabled user
      if (response.status == 202) {
        data.twoFactorAuthRequired = response.data.istwoFactorAuthRequired;
        if (data.twoFactorAuthRequired) {
          data.OTPNote = ApiService.getOTPConfirmationNote(response.data);
          data.errorMessage = '';
          data.showLoginForm = false;
          data.showOTPForm = true;
          data.tempAccessToken = response.data.accessToken;
          data.btnDisable = false;
          data.showError = false;
        }
      }
    }
  },
  redirectToConnectorScript: function (response, urlParams) {
    const accessToken = response.data.accessToken;
    const userId = response.data.userId;
    const state = urlParams.get('state');
    window.location.href = `${deltax.dxDatastudioConnectorUrl}?state=${state}&accessToken=${accessToken}&userId=${userId}`;
  }
};

export { ApiService };
